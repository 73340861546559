<template>
  <div class="calender-container font-menu-medium">
    <div class="calendar-prevWeek" @click="showPrevWeek">
      <img
        :src="require(`@/assets/img/01-Menu/icon-feather-chevron-left.svg`)"
        alt=""
      />
    </div>

    <div class="calendar-nowWeek" :class="{ bookClassPage: isBookClassPage }">
      <div class="week font-menu-small">
        <div class="date-content">
          <div class="item" v-for="(item, index) in weekNameArr" :key="index">
            {{ item }}
          </div>
        </div>
      </div>

      <div class="date">
        <div class="date-content">
          <div
            class="item"
            v-for="(day, index) in weekArr"
            :key="index"
            @click="chooseClassDate(day)"
          >
            <!--其他-->
            <!-- <div v-if="day.getMonth() + 1 !== currentMonth" class="other-month">
              <div class="dayTiem" :class="{ active: chooseDate === day }">
                {{ day.getDate() }}
              </div>
            </div> -->
            <!--今天-->

            <!-- <div
              v-if="
                day.getFullYear() == new Date().getFullYear() &&
                day.getMonth() == new Date().getMonth() &&
                day.getDate() == new Date().getDate()
              "
            >
              <div
                class="dayItem isToday"
                :class="{ active: chooseDate === day }"
              >
                {{ day.getDate() }}
              </div>
            </div> -->
            <!--本月-->

            <div
              class="dayItem"
              :class="{
                pointStyle: isPointStyle,
                active: chooseDate === day,
                isToday:
                  day.getFullYear() == new Date().getFullYear() &&
                  day.getMonth() == new Date().getMonth() &&
                  day.getDate() == new Date().getDate(),
              }"
            >
              {{ day.getDate() }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="calendar-nextWeek" @click="showNextWeek">
      <img src="@/assets/img/01-Menu/icon-feather-chevron-right.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { getFormatDate } from "@/utils/tools.js";

export default {
  props: {
    isBookClassPage: {
      type: Boolean,
      require: true,
      default: false,
    },
    isPointStyle: {
      type: Boolean,
      default: false,
    },
  },
  name: "week-date",
  data() {
    return {
      chooseDate: null,
      // currentYear: 1970,
      // currentMonth: 1,
      // currentDay: 1,
      // currentWeek: 1,
      weekArr: [],
      weekName: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      weekNameArr: [],
      behindDate: "",
      frontDate: "",
    };
  },
  mounted() {
    this.returnData(true);
  },
  created() {
    this.initData(null);
  },
  computed: {
    isToday() {
      return (
        this.weekArr[0].getFullYear() == new Date().getFullYear() &&
        this.weekArr[0].getMonth() == new Date().getMonth() &&
        this.weekArr[0].getDate() == new Date().getDate()
      );
    },
  },
  methods: {
    showPrevWeek() {
      if (this.isToday) {
        return;
      }
      this.weekPre();
      this.returnData(false);
    },
    showNextWeek() {
      this.weekNext();
      this.returnData(false);
    },
    formatDate(year, month, day) {
      const y = year;
      let m = month;
      if (m < 10) m = `0${m}`;
      let d = day;
      if (d < 10) d = `0${d}`;
      return `${y}-${m}-${d}`;
    },
    initData(cur) {
      let date = null;
      if (cur) {
        date = new Date(cur);
      } else {
        date = new Date();
      }
      console.log(date);
      // // 今日日期 几号
      // this.currentDay = date.getDate();
      // // 当前年份
      // this.currentYear = date.getFullYear();
      // // 当前月份
      // this.currentMonth = date.getMonth() + 1;
      // // this.currentWeek = date.getDay();
      // // // 1...6,0  // 星期几
      // // if (this.currentWeek === 0) {
      // //   this.currentWeek = 7;
      // // }
      // const str = this.formatDate(
      //   this.currentYear,
      //   this.currentMonth,
      //   this.currentDay
      // ); // 今日日期 年-月-日
      this.weekArr.length = 0;
      this.weekNameArr = [];
      // 今天是周日，放在第一行第7个位置，前面6个 这里默认显示一周，如果需要显示一个月，则第二个循环为 i<= 35- this.currentWeek
      // for (let i = this.currentWeek - 1; i >= 0; i -= 1) {
      //   const d = new Date(str);
      //   d.setDate(d.getDate() - i);
      //   this.weekArr.push(d);
      // }
      for (let i = 0; i <= 6; i++) {
        const d = new Date(date);
        d.setDate(date.getDate() + i);
        // console.log(d);
        const weekName = this.weekName[d.getDay()];
        this.weekNameArr.push(weekName);
        this.weekArr.push(d);
      }
      // console.log(this.weekArr);
      // console.log(this.weekArr);
      // this.behindDate = this.formatDate(
      //   this.weekArr[0].getFullYear(),
      //   this.weekArr[0].getMonth() + 1,
      //   this.weekArr[0].getDate()
      // );
      // this.frontDate = this.formatDate(
      //   this.weekArr[6].getFullYear(),
      //   this.weekArr[6].getMonth() + 1,
      //   this.weekArr[6].getDate()
      // );
    },
    // 上个星期
    weekPre() {
      // 如果当期日期是7号或者小于7号
      const d = this.weekArr[0];
      console.log(d);
      d.setDate(d.getDate() - 7);
      this.initData(d);
    },
    // 下个星期
    weekNext() {
      // 如果当期日期是7号或者小于7号
      const d = this.weekArr[6];
      console.log(d);
      d.setDate(d.getDate() + 1);
      this.initData(d);
    },
    // 上一個月  传入当前年份和月份
    pickPre(year, month) {
      const d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 下一個月  传入当前年份和月份
    pickNext(year, month) {
      const d = new Date(this.formatDate(year, month, 1));
      d.setDate(35);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 当前选择日期
    chooseClassDate(date) {
      if (
        date.getFullYear() == new Date().getFullYear() &&
        date.getMonth() == new Date().getMonth() &&
        date.getDate() == new Date().getDate()
      ) {
        return;
      }
      this.chooseDate = date;
      const formDate = this.formatDate(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );
      const dateSplitArr = String(date).split(" ");
      const dateTest =
        dateSplitArr[0] +
        ", " +
        dateSplitArr[1] +
        " " +
        parseInt(dateSplitArr[2]);
      console.log(dateTest, formDate);
      this.$emit("getFormDate", dateTest, formDate);
    },
    returnData(value) {
      const firstDay = value ? 32 : this.weekArr[0].getDate();
      const startDate =
        getFormatDate(this.weekArr[0]).split(" ")[0] + " 00:00:00";
      // this.weekNext();
      // this.weekNext();
      const endDate =
        getFormatDate(this.weekArr[6]).split(" ")[0] + " 00:00:00";
      // this.weekPre();
      // this.weekPre();
      const dateArr = [
        firstDay,
        this.weekArr[1].getDate(),
        this.weekArr[2].getDate(),
        this.weekArr[3].getDate(),
        this.weekArr[4].getDate(),
        this.weekArr[5].getDate(),
        this.weekArr[6].getDate(),
      ];
      let formDateArr = [];
      this.weekArr.forEach((item) => {
        const eleArr = String(item).split(" ");
        const ele = eleArr[0] + ", " + parseInt(eleArr[2]) + " " + eleArr[1];
        formDateArr.push(ele);
      });
      // console.log(formDateArr);

      this.$emit(
        "getRangeDate",
        startDate,
        endDate,
        dateArr,
        formDateArr,
        this.weekArr
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.calender-container {
  width: 100%;
  height: 100%;
  position: relative;
  // justify-content: space-between;
  align-items: center;
  .calendar-prevWeek,
  .calendar-nextWeek {
    width: 10px;
    height: 100%;
    position: absolute;
    font-size: 1.5rem;
    top: 0%;
    // line-height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    img {
      visibility: hidden;
    }
  }
  .calendar-prevWeek {
    left: 1%;
    background-image: url("../../../assets/img/01-Menu/icon-feather-chevron-left.svg");

    &:hover {
      background-image: url("../../../assets/img/01-Menu/icon-feather-chevron-yellow-left.svg");
    }
  }
  .calendar-nextWeek {
    right: 1.5%;
    background-image: url("../../../assets/img/01-Menu/icon-feather-chevron-right.svg");

    &:hover {
      background-image: url("../../../assets/img/01-Menu/icon-feather-chevron-yellow-right.svg");
    }
  }
  .calendar-nowWeek {
    cursor: default;
    width: 100%;
    height: 100%;
    // max-height: 72px;
    .date-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      // padding: 0 3%;
      .item {
        flex: 1;
        text-align: center;
        color: #cd4c3f;
      }
    }
    .date {
      margin-top: 1%;
      .item {
        color: #224e96;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .dayItem {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        cursor: default;
          border: 2px solid transparent ;
          line-height: 28px;
        box-sizing: border-box;
      }
      .pointStyle {
        cursor: pointer;
        &:hover {
          border: 2px solid #f4d737;
        box-sizing: border-box;
        }
      }
      .isToday {
        border: 2px solid #cd4c3f;
        box-sizing: border-box;
        cursor: not-allowed;
      }
      .active {
        width: 30px;
        height: 30px;
        border-radius: 50%;
          line-height: 28px;

        background: #f4d737;
      }
    }
  }
  .bookClassPage {
    padding-left: 12%;
    width: 88%;
  }
}
</style>
